<!-- 系统配置-设备等级配置 -->
<template>
    <div class="page_container height_100">
        <div class="box_head1">
            <span>设备等级配置</span>
        </div>
        <div class="bgc_FFFFFF pdt_20 pdl30 pdr30 faultCodeAnalysisBody">
                <el-table ref="multipleTable" :data="deviceLevelList" tooltip-effect="dark" style="width: 100%;"
                    class="" height="93%"
                    :default-sort="{prop: 'date', order: 'descending'}">
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column type="index" label="序号" width="80"></el-table-column>
                    <el-table-column prop="Name" label="等级名称" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="ID" label="等级编码" width=""></el-table-column>
                    <el-table-column prop="count" label="设备数量" width=""></el-table-column>
                    <el-table-column prop="recordTime" label="创建时间" width="" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="equipmentLevelDescription" label="备注" width="" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;">修改</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;">删除</span>
                            </el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
            <!-- dialog 增加 -->
            <el-dialog :visible.sync="dialogTableVisible" top='10vh' width="30%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">新增设备类型</div>
                <div class="flex justify_between pdl30 pdr30">
                    <el-form :inline="true" :model="formInline" label-width="100px" :rules="rules" ref="formInline"
                        class="mg_top_30 flex justify_between flex_column align_center">
                        <el-form-item label="类型名称：" prop="user">
                            <el-input v-model="formInline.user" placeholder="输入设备编码名称 不能重名" class="width_270">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="类型编码：" prop="user">
                            <el-input v-model="formInline.user" placeholder="输入设备编码名称 不能重名" class="width_270">
                            </el-input>
                        </el-form-item>
                        <!-- <el-form-item label="上级类型：" prop="user">
                            <el-select v-model="formInline.region" placeholder="" class="width_270 ">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item> -->
                    </el-form>
                </div>
                <el-form :model="formInline" label-width="100px" class=" pdl30 pdr30">
                    <el-form-item label="文档描述：" prop="user">
                        <el-input type="textarea" :rows="6" v-model="formInline.user" resize="none"
                            placeholder="请输入介绍信息" class="width_270 flex"></el-input>
                    </el-form-item>
                </el-form>

                <!-- 按钮 -->
                <div class="flex justify_center pdb_20 pdt_20">
                    <el-button type="primary" @click="dialogTableVisible = false">修改</el-button>
                </div>
            </el-dialog>
            <!-- dialog 编辑 -->
            <el-dialog :visible.sync="dialogTableVisible2" top='10vh' width="30%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">新增设备类型</div>
                <div class="flex justify_between pdl30 pdr30">
                    <el-form :inline="true" :model="formInline" label-width="100px" :rules="rules" ref="formInline"
                        class="mg_top_30 flex justify_between flex_column align_center">
                        <el-form-item label="类型名称：" prop="user">
                            <el-input v-model="formInline.user" placeholder="输入设备编码名称 不能重名" class="width_270">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="类型编码：" prop="user">
                            <el-input v-model="formInline.user" placeholder="输入设备编码名称 不能重名" class="width_270">
                            </el-input>
                        </el-form-item>
                        <!-- <el-form-item label="上级类型：" prop="user">
                            <el-select v-model="formInline.region" placeholder="" class="width_270 ">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item> -->
                    </el-form>
                </div>
                <el-form :model="formInline" label-width="100px" class=" pdl30 pdr30">
                    <el-form-item label="文档描述：" prop="user">
                        <el-input type="textarea" :rows="6" v-model="formInline.user" resize="none"
                            placeholder="请输入介绍信息" class="width_270 flex"></el-input>
                    </el-form-item>
                </el-form>

                <!-- 按钮 -->
                <div class="flex justify_center pdb_20 pdt_20">
                    <el-button type="primary" @click="dialogTableVisible2 = false">修改</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        equipmentLevelConfigList,
        // equipmentTypeConfigAdd,
        // equipmentLevelConfigDelete,
    } from "@/api/api_systemConfig";
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tabIndex: 0,
                value: '',
                input: '',
                deviceLevelList: [],
                dialogTableVisible: false,
                dialogTableVisible2: false,
                deleteNum: [],
                formInline: {
                    user: '',
                    region: ''
                },
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                tableData: [{
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }],
                multipleSelection: [],
                // dialog表单验证规则
                rules: {
                    user: [{
                            required: true,
                            message: '请输入活动名称',
                            trigger: 'blur'
                        },
                        {
                            min: 3,
                            max: 5,
                            message: '长度在 3 到 5 个字符',
                            trigger: 'blur'
                        }
                    ],
                    region: [{
                        required: true,
                        message: '请选择活动区域',
                        trigger: 'change'
                    }],
                }
            }
        },
        mounted() {
            this.equipmentLevelConfigList();
            // this.equipmentTypeConfigAdd();
            // this.equipmentLevelConfigDelete();
        },
        methods: {
            // handleSelectionChange(val) {
            //     // console.log(val)
            //     this.multipleSelection = val;
            //     //要批量删除的数组
            //     // // this.deleteNum = val[0].equipmentcategoryID; //选中的单个数据
            //     // console.log(this.deleteNum)

            //     console.log(val);
            // },
            //接口-开始
            //类型选择
            //table
            async equipmentLevelConfigList() {
                var param = {
                    // type: this.kpiAlgorithmType,
                    // equipmentLevelId: 1
                }
                if (this.input != null && this.input != "") {
                    param.equipmentLevelId = this.input;
                }
                console.log(param);
                const selectRes = await equipmentLevelConfigList(param);
                this.deviceLevelList = selectRes.data.message;
                // console.log(selectRes.data)
            },
            //日期格式
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            //input框查询
            changedeviceLevel() {
                this.equipmentLevelConfigList()
            },
            //增加
            
            //编辑
            
            //删除
            // async equipmentLevelConfigDelete() {
            //     var param = {
            //         // ID: this.currentInfo.ID, //编码
            //         // equipmentcategory_name: this.input2, //名称
            //         equipmentcategoryID: this.deleteNum,//ID名未改
            //         // equipmentcategoryID: '6',
            //     }
            //     // console.log(param);

            //     const selectRes = await equipmentLevelConfigDelete(param);
            //     // this.deviceTypeList = selectRes.data.rows;
            //     console.log(selectRes.data)
            // },
            // //批量删除
            // deletedeviceType() {
            //     this.equipmentLevelConfigDelete()
            // },

            //接口-结束
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .spanWidth {
        width: 80px;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }

    .alarmQuerySelectButton {
        width: 110px;
        height: 36px;
        background: #009BFD;
    }

    .faultCodeAnalysisBody {
        height: 95%;
    }
</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>